import { lazy, useEffect, useState } from "react";

import ThemeProvider from "shared/providers/styled-components/theme.provider";
import ToastProvider from "shared/providers/toast/toast.provider";

import { Route, Switch } from "shared/providers/router/router.package";

import withSuspense from "shared/hoc/with-suspense/with-suspense.hoc";

import { Maintenance } from "shared/components/maintenance";
import { NotFound } from "shared/components/not-found";
import { Spinner } from "shared/components/spinner";
import { Dock } from "../dock";
import GlobalModals from "../global-modals/global-modals.component";
import Layout from "../../containers/layout/layout.container";

import ProtectedRoute from "../../hoc/protected-route/protected-route.hoc";
import PortfolioProtectedRoute from "../../hoc/portfolio-protected-route/portfolio-protected-route.hoc";

import { setLocalStorageItem } from "shared/utils/local-storage";

import { WEB_AUTH_ADMIN_TOKEN } from "shared/common/data/constants";
import { IAppProps } from "./app.interfaces";

import theme from "shared/styles/Theme/index";
import GlobalStyles from "shared/styles";

const Login = withSuspense(
  lazy(() => import("../../pages/login/login.page")),
  false,
  "100vh"
);

const ForgottenPassword = withSuspense(
  lazy(() => import("../../pages/forgotten-password/forgotten.page")),
  false,
  "100vh"
);

const ResetPassword = withSuspense(
  lazy(() => import("../../pages/reset-password/reset.page")),
  false,
  "100vh"
);

const Portfolios = withSuspense(
  lazy(() => import("../../pages/portfolios/portfolios.page")),
  false,
  "full-layout"
);

const SinglePortfoliosRoutes = withSuspense(
  lazy(
    () =>
      import("../../pages/portfolios/single-portfolio/single-portfolio.routes")
  ),
  false,
  "full-layout"
);

const GlobalPropertiesRoutes = withSuspense(
  lazy(() => import("../../pages/properties/properties.routes")),
  false,
  "full-layout"
);

const GlobalUsersRoutes = withSuspense(
  lazy(() => import("../../pages/users/users.routes")),
  false,
  "full-layout"
);

const GlobalNotificationsRoutes = withSuspense(
  lazy(() => import("../../pages/notifications/notifications.routes")),
  false,
  "full-layout"
);

const App = ({ isMaintenance }: IAppProps) => {
  const [initLoaded, setInitLoaded] = useState(false);

  useEffect(() => {
    const queryString = window.location.search;

    if (queryString) {
      const urlParams = new URLSearchParams(queryString),
        loginToken = urlParams.get("loginToken");

      if (loginToken) {
        setLocalStorageItem(WEB_AUTH_ADMIN_TOKEN, loginToken);
      }
    }

    setInitLoaded(true);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <ToastProvider />

      {isMaintenance ? (
        <Maintenance />
      ) : !initLoaded ? (
        <Spinner $height="100vh" />
      ) : (
        <Switch>
          <Route exact path={["/", "/login"]} children={<Login />} />

          <Route
            exact
            path="/forgotten-password"
            children={<ForgottenPassword />}
          />

          <Route
            exact
            path="/reset-password/:code"
            children={<ResetPassword />}
          />

          <Route
            path={["/portfolios", "/properties", "/users", "/notifications"]}
            children={
              <ProtectedRoute>
                <Switch>
                  <Route
                    path="/portfolios/:id"
                    children={
                      <PortfolioProtectedRoute>
                        <SinglePortfoliosRoutes />
                      </PortfolioProtectedRoute>
                    }
                  />

                  <Route
                    children={
                      <Layout>
                        <Switch>
                          <Route
                            exact
                            path="/portfolios"
                            children={<Portfolios />}
                          />

                          <Route
                            path="/properties"
                            children={<GlobalPropertiesRoutes />}
                          />

                          <Route
                            path="/notifications"
                            children={<GlobalNotificationsRoutes />}
                          />

                          <Route
                            path="/users"
                            children={<GlobalUsersRoutes />}
                          />
                        </Switch>
                      </Layout>
                    }
                  />
                </Switch>

                <GlobalModals />

                <Dock />
              </ProtectedRoute>
            }
          />

          <Route children={<NotFound url="/portfolios" />} />
        </Switch>
      )}
    </ThemeProvider>
  );
};

export default App;
