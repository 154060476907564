import { PropsWithChildren } from "react";
import { useReactiveVar } from "shared/providers/apollo/apollo.package";
import { sidebarExpandedVar } from "../../common/apollo/vars/sidebar.var";

import { LayoutWrapper } from "shared/components/layout";
import { Main } from "shared/components/layout/main";
import { Topbar } from "../../components/topbar";
import { Sidebar } from "../../components/sidebar";

const Layout = ({ children }: PropsWithChildren) => {
  const expanded = useReactiveVar(sidebarExpandedVar);

  return (
    <LayoutWrapper expanded={expanded}>
      <Topbar />
      <Sidebar />

      <Main>{children}</Main>
    </LayoutWrapper>
  );
};

export default Layout;
